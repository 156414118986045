import { createGlobalStyle } from "styled-components"
import { vars } from "../../variables"

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    position: relative;
    margin: 0;
    padding: 0;
    color: ${vars.brandcolor3};
    font-family: ${vars.fontPrimary};
    font-weight: 400;
    font-size: 18px;
    line-height: 1.45;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    background-color: #fff;
  }
/* 
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: ${vars.fontSecondary};
  }

  h1 {
    font-weight: bold
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    margin-bottom: 3px;
    font-weight: bold;
    font-family: ${vars.fontPrimary};
  }

  p {
    margin: 0 0 20px 0;
    padding: 0;
  }


  p.last {
    margin: 0;
  }
  .container {
    lost-center: 980px;
    padding: 0 15px;
  }

  .row {
    lost-utility: clearfix;
  }

  .section {
    padding: 0 0 40px;

    @include sm {
      padding: 0 0 80px;
    }

    &-title {
      padding: 30px 0;
      margin-bottom: 20px;
      text-align: center;
      background: url('./common/images/star-rope.jpg') center center no-repeat;
      background-size: contain;

      @include sm {
        padding: 60px 0;
      }
    }

    &-narrow-content {
      max-width: 650px;
      margin: 0 auto;
    }
  }

  .nowrap {
    white-space: nowrap;
  } */
`

export default GlobalStyle
