import { media, vars } from "../../variables.js"
import styled, { css } from "styled-components"
import { Link as RouterLink } from "gatsby"
import starRope from "./images/star-rope.jpg"

const Section = styled.section`
  padding: 0 0 40px;

  ${media.sm`
    padding: 0 0 80px;
  `}
`

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  max-width: 980px;
`

const MedContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;

  ${media.sm`
    width: 500px;
  `}
  
  ${media.md`
    width: 768px;
  `}
  
  ${media.lg`
    width: 992px;
  `}
`

const SmallContainer = styled(Container)`
  max-width: 88.53vw;
  margin: 0 auto;
  padding: 0 6.66vw;

  ${media.sm`
    max-width: 50vw;
    padding: 0 3.64vw;
  `}
`
const H = css`
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: ${vars.fontSecondary};
`

const H1 = styled.h1`
  ${H}
  font-weight: bold;
`

const H2 = styled.h2`
  ${H}
  font-size: 35px;
`

const H3 = styled.h3`
  ${H}
  font-size: 30px;
`

const H4 = styled.h4`
  ${H}
  margin-bottom: 3px;
  font-weight: bold;
  font-family: ${vars.fontPrimary};
`

const SectionHeading = styled(H2)`
  padding: 30px 0;
  margin-bottom: 20px;
  text-align: center;
  background: url(${starRope}) center center no-repeat;
  background-size: contain;

  ${media.sm` 
    padding: 60px 0;
  `}
`

const P = styled.p`
  padding: 0;
  margin: ${({ noMargin }) => (noMargin ? "0" : "0 0 1em")};
  line-height: 1.4em;
`

const Footnote = styled(P)`
  font-size: 1.89vw;
  ${media.sm`
    font-size: 0.73vw;
  `}
`

const Link = styled(RouterLink)`
  color: ${vars.brandcolor1};
  text-decoration: none;
`

const NoWrap = styled.span`
  white-space: nowrap;
`

export {
  Section,
  SectionHeading,
  Container,
  MedContainer,
  SmallContainer,
  H1,
  H2,
  H3,
  H4,
  P,
  Footnote,
  Link,
  NoWrap,
}
