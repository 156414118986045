import React, { Fragment, useRef, useEffect, useState } from "react"
import useIntersect from "../../hooks/useIntersect"
import { isIntersectionObserverSupported } from "../../utils/intersectionObserver"

const LazyLoadContainer = ({
  children,
  rootMargin = "0px 0px 300px",
  enabled = true,
  onLoad = () => {},
}) => {
  const intersectionRef = useRef()
  const [isLoadable, setIsLoadable] = useState(false)
  const [setNode, entry] = useIntersect({
    rootMargin,
  })

  useEffect(() => {
    setNode(intersectionRef.current)
  }, [setNode])

  useEffect(() => {
    if (!isIntersectionObserverSupported() || entry.isIntersecting) {
      setIsLoadable(true)
      setNode(null)
      onLoad()
    }
  }, [entry, onLoad, setNode])

  return isLoadable || !enabled ? (
    <Fragment>{children}</Fragment>
  ) : (
    <div ref={intersectionRef} />
  )
}

export default LazyLoadContainer
