import React, { Fragment } from "react"
import styled from "styled-components"
import LazyLoadContainer from "../LazyLoadContainer/LazyLoadContainer"
import { media } from "../../variables"
import {
  Container,
  MedContainer,
  SectionHeading,
  H3,
  P,
} from "../BaseElements/BaseElements"
import blackWave from "./images/bg-wave-black.jpg"
// import Map from "../Map/Map"

function Footer() {
  return (
    <Fragment>
      <FooterSection>
        <MedContainer>
          <SectionHeading>Find Us</SectionHeading>

          <P>
            Best View Cabins are located at <strong>1348 Highway 1</strong> in the
            community of Clementsport which rests between Digby and Annapolis
            Royal, near the Bay of Fundy. Clementsport is approximately 210 km
            from Halifax on the north west side of Nova Scotia. For directions, find us using{" "}
            <a
              href="https://goo.gl/maps/Cb7rrekRVNF2"
              target="_blank"
              rel="noopener noreferrer"
            >
              google maps
            </a>
            .
          </P>
        </MedContainer>
      </FooterSection>
      {/* <LazyLoadContainer>
              <Map />
            </LazyLoadContainer> */}
      <StyledFooter>
        <Container>
          <ContactContainer>
            <div>
              <H3>Best View Cabins</H3>
              <P>
                1348 Highway 1<br />
                RR2 Clementsport
                <br />
                Annapolis County, Nova Scotia
                <br />
                B0S 1E0
                <br />
                Canada
              </P>
            </div>
            <Email>
              <H3>Email</H3>
              <P>contact@bestview.ca</P>
            </Email>
            <Telephone>
              <H3>Telephone</H3>
              <P>
                <PhoneLink href="tel:+12894558905">289-455-8905</PhoneLink>
              </P>
            </Telephone>
          </ContactContainer>
          <FooterBottom>
            Copyright &copy; 2000-
            {new Date().getFullYear()} Best View Cabins. All rights reserved.
          </FooterBottom>
          {
            //   <FooterBottom>
            //   <div className={s.footerLeft}>
            //     Copyright &copy; 2000-
            //     {new Date().getFullYear()} Best View Cabins. All rights reserved.
            //   </div>
            //   <div className={s.footerRight}>
            //     <a className={s.link} href="#privacy">
            //       Privacy Policy
            //     </a>
            //   </div>
            // </FooterBottom>
          }
        </Container>
      </StyledFooter>
    </Fragment>
  )
}

const FooterSection = styled.div`
  padding: 0 0 25px;
`

const StyledFooter = styled.footer`
  background: #1f1f1f;
  border-top: 1px solid #5f5f5f;
  background-image: url(${blackWave});
  color: #fff;
  padding: 30px 0 15px;
`

const FooterBottom = styled.div`
  font-size: 14px;
  padding-top: 50px;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.sm`
    flex-direction: row;
  `}

  > div {
    flex: 0 0 100%;

    ${media.sm`
      flex: 0 0 33%;
    `}
  }
`
const Email = styled.div`
  text-align: left;

  ${media.sm`
    text-align: center;
 `}
`
const Telephone = styled.div`
  text-align: left;

  ${media.sm`
    text-align: right;
 `}
`
const PhoneLink = styled.a`
  text-decoration: none;
  color: #fff;
`

// .contactColumn {
//  text-align: left;

//  @include sm {
//   & { lost-column: 1/3; }
//  }
// }

// .email {
//   @include sm {
//     text-align: center;
//   }
// }

// .telephone {
//   @include sm {
//     text-align: right;
//   }
// }

// .link {
//   color: $font-light-color;
// }

export default Footer
