import React from "react"
import styled from "styled-components"

import { vars, media } from "../../variables"
import { Container, NoWrap } from "../BaseElements/BaseElements"

const Header = () => (
  <StyledHeader>
    <StyledContainer>
      <HeaderLeft>1348 Highway 1, Clementsport, NS</HeaderLeft>
      {
        // <div className={s.HeaderRight}>
        //   For Reservations Call{' '}
        //   <NoWrap>
        //     <HeaderLink href="tel:+19026383264">
        //       <strong>902-638-3264</strong>
        //     </HeaderLink>
        //   </NoWrap>
        // </div>

        <HeaderRight>
          For reservations{" "}
          <NoWrap>
            <HeaderLink
              href="https://www.airbnb.ca/users/351412186/listings"
              target="_blank"
            >
              <strong>visit us on Airbnb</strong>
            </HeaderLink>
          </NoWrap>
        </HeaderRight>
      }
    </StyledContainer>
  </StyledHeader>
)

const StyledHeader = styled.header`
  background: ${vars.brandcolor1};
  color: #fff;
  border-bottom: 1px solid #003c71;
  border-top: 1px solid #0b8dff;
  padding: 5px 0;
  position: fixed;
  width: 100%;
  z-index: 200;
  top: 0;
  font-size: 16px;

  ${media.sm`
    font-size: 18px;
  `}
`

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`

const HeaderLeft = styled.div`
  text-align: left;
  display: none;

  ${media.sm`
    display: block;
    flex: 0 1 auto;
  `}
`

const HeaderRight = styled.div`
  text-align: center;
  flex: 1 1 auto;
  ${media.sm`
    text-align: right;
    flex: 0 1 auto;
  `}
`

const HeaderLink = styled.a`
  text-decoration: none;
  color: #fff;
`

export default Header
